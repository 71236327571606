import GraphEditor from "./GraphEditor.vue";

export default {
  install(Vue) {
    Vue.component(GraphEditor.name, GraphEditor);
  }
}

// commit

